import {Component, EventEmitter, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {RowEvent, TableColumn, TableRow} from '../../models';
import {TableComponent} from '../../components/table.component';
import {DynamicCell} from '../../core/dynamic-cell.directive';

@Component({
  selector: 'inf-table-slide-toggle',
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule],
  template: `
    <mat-slide-toggle [checked]="row[column.key]" [disabled]="row['disabled']"
                      (change)="toggleLock($event)"></mat-slide-toggle>
  `
})
export class TableSlideToggleComponent implements DynamicCell {
  @Input() column: TableColumn<any>;
  @Input() parent: TableComponent<any>;
  @Input() row: TableRow;
  @Input() onRowEvent: EventEmitter<RowEvent>;

  toggleLock(e: any) {
    this.onRowEvent.emit({event: 'SlideToggleToggle', sender: {row: this.row, e, column: this.column}})
  }
}
