import {rfdc} from './rfdc';

export interface DeepCloneOptions {
  circles?: boolean;
  proto?: boolean;
}

export const deepClone = (valToClone: any, options?: DeepCloneOptions) => {
  const defaultOptions = {
    circles: false,
    proto: true,
    ...(options ? options : {})
  };

  return rfdc(defaultOptions)(valToClone);
};
