import {ThemePalette} from '@angular/material/core';

export declare type PredicateFn = (row: any) => boolean;

export interface ContextMenuItem {
  name: string;
  text: string;
  icon?: string;
  color?: ThemePalette;
  disabled?: boolean | PredicateFn;
  visible?: boolean | PredicateFn;
  divider?: boolean;
}
