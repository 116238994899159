<div class="header">
  <h3 mat-dialog-title>Eigene View speichern</h3>
  <mat-icon (click)="onCancel()">clear</mat-icon>
</div>
<mat-dialog-content class="dialogContent">
  <mat-form-field class="full-width-field">
    <mat-label>Titel</mat-label>
    <input matInput name="title" [(ngModel)]="title" required #titleModel="ngModel" />
    <mat-hint>Bitte vergeben Sie einen Titel für den neuen View mit den ausgewählten Filtereinstellungen</mat-hint>
    <mat-error *ngIf="titleModel.invalid && (titleModel.dirty || titleModel.touched)">
      <div *ngIf="titleModel.errors?.['required']">Bitte vergeben Sie einen Titel für den neuen View mit den ausgewählten Filtereinstellungen</div>
    </mat-error>
  </mat-form-field>
  <mat-radio-group [(ngModel)]="audience">
    <mat-label>Sichtbarkeit</mat-label>
    <br />
    <mat-radio-button class="radio" value="1">organisationsweit</mat-radio-button>
    <mat-radio-button class="radio" value="2">privat</mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="button" (click)="onCancel()">Abbrechen</div>
  <button class="button" mat-raised-button color="primary" (click)="onSave()">speichern</button>
</mat-dialog-actions>
