import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import {RowEvent, TableColumn, TableRow} from '../../models';
import {TableComponent} from '../../components/table.component';
import {DynamicCell} from '../../core/dynamic-cell.directive';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'inf-table-dl-link',
  standalone: true,
  imports: [CommonModule],
  template: `
    <a (click)="handle($event)" [href]="href">{{row[column.key]}}</a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableDlLinkComponent implements DynamicCell, OnInit {
  constructor(private http: HttpClient) {}

  @Input() column: TableColumn<any>;
  @Input() parent: TableComponent<any>;
  @Input() row: TableRow;
  @Input() onRowEvent: EventEmitter<RowEvent>;

  href: string;

  ngOnInit() {
    this.href = `${this.column.option.basePath}/${this.row[this.column.option.idKey]}`;
  }

  handle(e: Event) {
    e.preventDefault();
    const url = this.href;
    const headers = new HttpHeaders().append('Accept', `application/pdf`);
    return this.http
      .get(url, {
        headers,
        responseType: 'blob'
      })
      .subscribe(blob => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.row[this.column.key];
        link.click();
        window.URL.createObjectURL(blob);
      });
  }
}
