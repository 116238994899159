import {ChangeDetectionStrategy, Component, EventEmitter, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {RowEvent, TableColumn, TableRow} from '../../models';
import {TableComponent} from '../../components/table.component';
import {DynamicCell} from '../../core/dynamic-cell.directive';

@Component({
  selector: 'inf-table-edit-draft',
  imports: [CommonModule, MatIconModule],
  template: `<mat-icon (click)="onClick($event)">add_box</mat-icon>`,
  standalone: true,
  styleUrls: [`./table-add.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableAddComponent implements DynamicCell {
  @Input() column: TableColumn<any>;
  @Input() parent: TableComponent<any>;
  @Input() row: TableRow;
  @Input() onRowEvent: EventEmitter<RowEvent>;

  onClick(e: MouseEvent) {
    e.stopPropagation()
    this.onRowEvent.emit({ event: 'AddClicked', sender: { row: this.row, e } });
  }
}
