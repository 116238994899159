import { Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { RoleGuard } from '@core/role.guard';
import { UnauthorizedComponent } from '@pages/unauthorized/unauthorized.component';
import { OrganisationShellComponent } from '@pages/organisation/organisation-shell.component';
import { ToebShellComponent } from '@pages/toebs/toeb-shell.component';
import { AufgabenShellComponent } from '@pages/aufgaben/aufgaben-shell.component';
import { NutzerShellComponent } from '@pages/nutzer/nutzer-shell.component';
import { EinstellungenShellComponent } from '@pages/einstellungen/einstellungen-shell.component';
import { VorgaengeShellComponent } from '@pages/vorgaenge/vorgaenge-shell.component';
import { TransaktionenShellComponent } from '@pages/transaktionen/transaktionen-shell.component';
import { DashboardShellComponent } from '@pages/dashboard/dashboard-shell.component';
import { NavigationService } from '@core/services';
import { Role } from '@app/models';
import { NewsShellComponent } from '@pages/news/news-shell.component';

import { RoutePath } from '@app/models'

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: RoutePath.Dashboard, pathMatch: 'full' },
  {
    path: RoutePath.Dashboard,
    component: DashboardShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.routes')
  },
  {
    path: RoutePath.Organisation,
    component: OrganisationShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/organisation/organisation.routes')
  },
  {
    path: RoutePath.Toebs,
    component: ToebShellComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'alle',
        loadComponent: () => import('./pages/toebs/components/toeb/toeb.component').then(mod => mod.ToebComponent),
        canActivate: [RoleGuard],
        data: { expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBPflege] }
      },
      {
        path: 'entwurf',
        loadComponent: () => import('./pages/toebs/components/toeb-draft/toeb-draft.component').then(mod => mod.ToebDraftComponent),
        canActivate: [RoleGuard],
        data: { expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBPflege] }
      },
      {
        path: 'freigabe',
        loadComponent: () => import('./pages/toebs/components/toeb-freigabe/toeb-freigabe.component').then(mod => mod.ToebFreigabeComponent),
        canActivate: [RoleGuard],
        data: { expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.ToeBFreigabe] }
      }
    ]
  },
  {
    path: RoutePath.Transaktionen,
    component: TransaktionenShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/transaktionen/transaktionen.routes')
  },
  {
    path: RoutePath.Nutzer,
    component: NutzerShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/nutzer/nutzer.routes')
  },
  {
    path: RoutePath.News,
    component: NewsShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/news/news.routes')
  },
  {
    path: RoutePath.Einstellungen,
    component: EinstellungenShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/einstellungen/einstellungen.routes')
  },
  {
    path: RoutePath.Aufgaben,
    component: AufgabenShellComponent,
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/aufgaben/aufgaben.routes') },
  {
    path: RoutePath.Vorgaenge,
    component: VorgaengeShellComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'alle',
        loadComponent: () => import('./pages/vorgaenge/components/vorgaenge/vorgaenge.component').then(mod => mod.VorgaengeComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice],
          title: 'Alle Vorgänge',
          filters: []
        }
      },
      {
        path: 'alle-teilvorgaenge',
        loadComponent: () => import('./pages/vorgaenge/components/teilvorgaenge/teilvorgaenge.component').then(mod => mod.TeilvorgaengeComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice],
          title: 'Alle Teilvorgänge',
          filters: []
        }
      },
      {
        path: 'dl-vorgaenge',
        loadComponent: () => import('./pages/vorgaenge/components/dl-vorgaenge/dl-vorgaenge.component').then(mod => mod.DlVorgaengeComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Diensleistung],
          title: 'Alle DL-Vorgänge',
          filters: []
        }
      },
      {
        path: 'dl-vorgaenge-nicht-zugewiesene',
        loadComponent: () => import('./pages/vorgaenge/components/dl-vorgaenge/dl-vorgaenge.component').then(mod => mod.DlVorgaengeComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Diensleistung],
          title: 'Nicht zugewiesene DL-Vorgänge',
          filters: [{ column: 'dlOrganisation', operator: 'null', term: '', not: false }]
        }
      },
      {
        path: 'bsa-uebertragung',
        loadComponent: () => import('./pages/vorgaenge/components/bsa-uebertragung/bsa-uebertragung.component').then(mod => mod.BsaUebertragungComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin],
          filters: [{ column: 'bsa_status', operator: 'null', term: '', not: true }],
          title: 'BSA Übertragung'
        }
      },
      {
        path: 'oplex-uebertragung',
        loadComponent: () => import('./pages/vorgaenge/components/oplex-uebertragung/oplex-uebertragung.component').then(mod => mod.OplexUebertragungComponent),
        canActivate: [RoleGuard],
        data: {
          expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin],
          filters: [],
          title: 'OPLEX Übertragung'
        }
      }
    ],
    providers: [NavigationService]
  },
  {
    path: RoutePath.AuftraggeberBeziehung,
    loadComponent: () =>
      import('@pages/organisation/components/auftraggeber-beziehung/auftraggeber-beziehung.component').then(mod => mod.AuftraggeberBeziehung),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.InfrestAdmin, Role.SuperAdmin, Role.Kundenservice] }
  },
  {
    // Needed for handling redirect after login
    path: RoutePath.Auth,
    component: MsalRedirectComponent
  },
  {
    path: RoutePath.Unauthorized,
    canActivate: [MsalGuard],
    component: UnauthorizedComponent
  }
];
