import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormModule } from '@components/dynamic-form/dynamic-form.module';
import { MatButtonModule } from '@angular/material/button';
import { TableModule } from '@components/table';
import { TableWrapperComponent } from '@components/table-wrapper/table-wrapper.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';

type Option = {
  label: string;
  value: string;
};

@Component({
  selector: 'inf-orderable-select-dialog-component',
  standalone: true,
  imports: [
    CommonModule,
    DynamicFormModule,
    MatButtonModule,
    TableWrapperComponent,
    TableModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatChipsModule,
    MatMenuModule,
    DragDropModule
  ],
  template: `
    <div class="inf-orderable-select-dialog-component">
      <div class="header">
        <span mat-dialog-title>{{ title }}</span>
      </div>
      <mat-dialog-content>
        <div class="select-list">
          <div>Available</div>
          <div>Selected</div>
          <div class="select-options">
            <div class="select-options-item" *ngFor="let option of notSelectedOptions$ | async; let i = index" (click)="toggleSelectedOption(option)">
              <span>{{ option.label }}</span>
              <mat-icon>chevron_right</mat-icon>
            </div>
          </div>
          <div class="selected-options" cdkDropList cdkDropListLockAxis="y" cdkDropListOrientation="vertical" dir="ltr">
            <div
              class="selected-options-item"
              *ngFor="let option of selectedOptions$ | async; let i = index"
              cdkDrag
              cdkDragPreviewContainer="parent"
              [cdkDragData]="{ optionIndex: i, columnTitle: option.label }"
              (cdkDragDropped)="optionMenuDropped($event)"
              (click)="$event.stopPropagation(); $event.preventDefault()">
              <div class="selected-options-item-placeholder" *cdkDragPlaceholder></div>
              <mat-icon (click)="toggleSelectedOption(option)">close</mat-icon>
              <span>{{ option.label }}</span>
              <mat-icon cdkDragHandle>drag_indicator</mat-icon>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button class="button" mat-button type="button" (click)="cancel()">Abbrechen</button>
        <button class="button" mat-raised-button color="primary" (click)="save()" [disabled]="selected?.length === 0 && !dialogData['emptySelectionAllowed']">
          Speichern
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [
    `
      @use '../../../../styles/base/index' as inf;

      :host ::ng-deep inf-table-wrapper {
        .inf-table-wrapper {
          padding: 0 !important;
        }
      }

      .header {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 0 10px;

        span {
          //margin-bottom: 16px;
          font-weight: 500;
          font-size: inf.$font-size-normal;
        }

        mat-icon {
          margin-top: 1px;
          cursor: pointer;
        }
      }

      mat-dialog-actions {
        justify-content: flex-end;

        .button {
          margin: 0 8px;
          cursor: pointer;
        }
      }

      mat-dialog-content {
        border-top: solid 1px #e5e5e5;
        border-bottom: solid 1px #e5e5e5;

        padding-top: 16px;
        padding-bottom: 8px;

        .table {
          height: 40vh;
        }
      }

      .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
        padding-top: 22px !important;
        padding-bottom: 6px !important;
      }

      .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
        //.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
        display: block !important;
        font-size: 14px !important;
      }

      .mat-mdc-option .mdc-list-item__primary-text {
        font-size: 14px !important;
      }

      .select-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        height: 100%;
        overflow: hidden;
      }

      .select-options,
      .selected-options {
        //background: var(--color-primary-light);
        overflow-y: scroll;
        border: 1px solid var(--color-border);
      }

      .select-options {
        .select-options-item {
          display: grid;
          grid-template-columns: 1fr 20px;
          grid-column-gap: 0.5rem;
          column-gap: 0.5rem;
          border-bottom: 1px solid var(--color-border);
          cursor: pointer;
          padding: 0.5rem;
          background: var(--color-primary-light);
        }
      }

      .selected-options {
        .selected-options-item {
          display: grid;
          grid-template-columns: 25px 1fr 20px;
          grid-column-gap: 0.5rem;
          column-gap: 0.5rem;
          border-bottom: 1px solid var(--color-border);
          padding: 0.5rem;
          background: var(--color-primary-light);
          mat-icon {
            cursor: pointer;
            &:hover {
              color: var(--color-accent);
            }
          }
        }
        .selected-options-item-placeholder {
          background: var(--color-accent);
          border: dotted 2px var(--color-primary);
          min-height: 40px;
          transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
      }
    `
  ]
})
export class OrderableSelectDialogComponent implements OnInit {
  constructor(
    private _dialogRef: MatDialogRef<OrderableSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { model?: any[]; options?: any[]; title: string }
  ) {
    this._dialogRef.disableClose = true;
  }

  title: string = '';
  selected: string[] = [];
  options: Option[] = [];

  private selectedSubject = new BehaviorSubject<string[]>(this.selected);

  selectedOptions$: Observable<Option[]> = this.selectedSubject
    .asObservable()
    .pipe(map(selected => selected.map(value => this.options.find(option => option.value === value))));

  notSelectedOptions$: Observable<Option[]> = this.selectedSubject
    .asObservable()
    .pipe(map(selected => this.options.filter(option => !selected.includes(option.value))));

  async ngOnInit() {
    this.title = this.dialogData.title;
    this.options = this.dialogData.options ?? [];
    this.selected = Array.isArray(this.dialogData?.model) ? [...this.dialogData.model] : [];
    this.selectedSubject.next(this.selected);
  }

  optionMenuDropped(event: CdkDragDrop<any>): void {
    moveItemInArray(this.selected, event.previousIndex, event.currentIndex);
    this.selectedSubject.next(this.selected);
  }

  toggleSelectedOption(option: Option) {
    if (this.selected.includes(option.value)) {
      this.selected = this.selected.filter(value => value !== option.value);
    } else {
      this.selected = this.selected.concat([option.value]);
    }
    this.selectedSubject.next(this.selected);
  }

  save() {
    if (this.selected?.length > 0 || this.dialogData['emptySelectionAllowed']) {
      this._dialogRef.close({
        action: 'save',
        model: {
          selectedValues: this.selected
        }
      });
    }
  }

  cancel() {
    this._dialogRef.close({
      action: 'cancel'
    });
  }
}
