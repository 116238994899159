import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { ContextMenuItem } from '../models/context-menu-model';
import { getObjectProp, isNullOrUndefined } from './types';
import { RowEvent, TableEvent, TableRow, TableSelectionMode } from '../models/table-row.model';
import { DynamicTableData } from '../models/dynamic-table-data.model';
import { deepClone } from '../utils';
import { TableColumn } from '../models/table-column.model';
import { TableSetting } from '../models/table-settings.model';
import { TablePagination, TablePaginationMode } from '../models/table-pagination.model';
import { TableVirtualScrollDataSource } from './table-data-source';
import { TableService } from '../services/table.service';
import { TableScrollStrategy } from './fixed-size-table-virtual-scroll-strategy';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Directive({
  selector: '[core]'
})
export class TableCoreDirective<T extends TableRow> {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport!: CdkVirtualScrollViewport;

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('checkboxColumnHeader') checkboxColumnHeader: TemplateRef<unknown>;
  @ViewChild('inputDropdown') dropdownTemplate: TemplateRef<unknown>;
  @ViewChild('defaultInputDropdownTrigger')
  defaultInputDropdownTrigger: TemplateRef<unknown>;
  @ViewChild('inputCheckbox') checkboxTemplate: TemplateRef<unknown>;
  @ViewChild('fileLink') fileTemplate: TemplateRef<unknown>;
  @ViewChild('checkMark', { static: true })
  checkMarkTemplate: TemplateRef<unknown>;

  @Input()
  get tableName() {
    return this.tableService.tableName;
  }

  set tableName(value: string) {
    this.tableService.tableName = value;
  }

  @Input() dataSource: BehaviorSubject<DynamicTableData<T>>;
  @Input() contextMenuItems: ContextMenuItem[] = [];
  @Input() rowContextMenuItems: ContextMenuItem[];
  @Input() defaultWidth: number = null;
  @Input() minWidth = 120;
  @Input() backgroundColor: string = null;

  @Input()
  get ScrollStrategyType() {
    return this.tableSetting.scrollStrategy;
  }

  set ScrollStrategyType(value: TableScrollStrategy) {
    this.viewport['_scrollStrategy'].scrollStrategyMode = value;
    this.tableSetting.scrollStrategy = value;
  }

  @Input()
  get columns() {
    return this.tableColumns;
  }

  set columns(columns: TableColumn<T>[]) {
    (columns || []).forEach((c: TableColumn<T>, i: number) => {
      const settingColumns = (this.tableSetting?.columnSetting || []).filter(s => s.key === c.key);
      const settingColumn = settingColumns.length > 0 ? settingColumns[0] : null;
      c.exportable = c.exportable || true;
      c.toExport = c.toExport || ((row, type) => (typeof row === 'object' ? row[c.key] : ''));
      c.enableContextMenu = c.enableContextMenu || true;
      c.display = getObjectProp('display', 'visible', settingColumn, c);
      c.filter = getObjectProp('filter', 'client-side', settingColumn, c);
      c.sort = getObjectProp('sort', 'client-side', settingColumn, c);
      c.sticky = getObjectProp('sticky', 'none', settingColumn, c);
      c.width = getObjectProp('width', this.defaultWidth, settingColumn, c);
      const unit = c.widthUnit || 'px';
      const style = unit === 'px' ? c.width + 'px' : `calc( ${c.widthPercentage}% )`;
      if (c.width) {
        c.style = {
          ...c.style,
          'max-width': style,
          'min-width': style
        };
      }
    });
    this.tableColumns = columns;
    this.updateColumn();
  }

  @Input()
  get paginationMode() {
    return this.tablePagingMode;
  }

  set paginationMode(value: TablePaginationMode) {
    this.tablePagingMode = value;
    this.updatePagination();
  }

  @Input()
  get pagination() {
    return this._tablePagination;
  }

  set pagination(value: TablePagination) {
    if (value && value !== null) {
      this._tablePagination = value;
      if (isNullOrUndefined(this._tablePagination.pageSizeOptions)) {
        this._tablePagination.pageSizeOptions = [5, 10, 25, 100];
      }
      if (isNullOrUndefined(this._tablePagination.pageSize)) {
        this._tablePagination.pageSize = this._tablePagination.pageSizeOptions[0];
      }
      this.updatePagination();
    }
  }

  @Input()
  get rowSelectionModel() {
    return this._rowSelectionModel;
  }

  set rowSelectionModel(value: SelectionModel<T>) {
    // console.log('model', value);
    if (!isNullOrUndefined(value)) {
      if (this._rowSelectionMode && value && this._rowSelectionMode !== 'none') {
        this._rowSelectionMode = value.isMultipleSelection() === true ? 'multiple' : 'single';
      }
      this._rowSelectionModel = value;
    }
  }

  @Input()
  get rowSelectionMode() {
    return this._rowSelectionMode;
  }

  set rowSelectionMode(selection: TableSelectionMode) {
    selection = selection || 'none';
    const isSelectionColumn = selection === 'single' || selection === 'multiple';
    if (
      this._rowSelectionModel === null ||
      (this._rowSelectionModel.isMultipleSelection() === true && selection === 'single') ||
      (this._rowSelectionModel.isMultipleSelection() === false && selection === 'multiple')
    ) {
      if (!this._rowSelectionModel) {
        // In-built SelectionModel has issues with Comparing Objects
        // So for angular 14 and below new ComparableSelection Model is created
        // to compare using a compare function

        this._rowSelectionModel = new SelectionModel<T>(selection === 'multiple', []);
        // this._rowSelectionModel = new SelectionModel<T>();
      }
    }
    if (this.displayedColumns?.length > 0 && !isSelectionColumn && this.displayedColumns[0] === 'row-checkbox') {
      this.displayedColumns.shift();
    } else if (this.displayedColumns?.length > 0 && isSelectionColumn && this.displayedColumns[0] !== 'row-checkbox') {
      this.displayedColumns.unshift('row-checkbox');
    }
    this._rowSelectionMode = selection;
  }

  @Input()
  get showProgress() {
    return this.progressColumn.length > 0;
  }

  set showProgress(value: boolean) {
    this.progressColumn = [];
    if (value === true) {
      this.progressColumn.push('progress');
    }
  }

  @Input() sticky: boolean;
  @Input() pending: boolean;
  @Input() rowHeight = 40;
  @Input() headerHeight = 40;
  @Input() headerEnable = true;
  @Input() showNoData: boolean;

  @Output() onTableEvent: EventEmitter<TableEvent> = new EventEmitter<TableEvent>();
  @Output() onRowEvent: EventEmitter<RowEvent> = new EventEmitter<RowEvent>();
  @Output() settingChange: EventEmitter<{
    type: 'create' | 'save' | 'apply' | 'delete' | 'default' | 'select' | 'error';
    setting: TableSetting;
  }> = new EventEmitter();
  @Output() paginationChange: EventEmitter<TablePagination> = new EventEmitter<TablePagination>();

  public tvsDS: TableVirtualScrollDataSource<T> = new TableVirtualScrollDataSource<T>([]);
  public tablePagingMode: TablePaginationMode = 'none';
  public viewportClass: 'viewport' | 'viewport-with-pagination' = 'viewport-with-pagination';
  public progressColumn: string[] = [];
  public displayedColumns: string[] = [];
  public tableColumns: TableColumn<T>[];
  public tableSetting: TableSetting;

  private _rowSelectionMode: TableSelectionMode;
  private _rowSelectionModel = new SelectionModel<T>();
  private _tablePagination: TablePagination = {};

  constructor(public tableService: TableService, public cd: ChangeDetectorRef) {
    this.showProgress = true;
    this.tableSetting = {
      columnSetting: null,
      visibleActionMenu: null
    };
  }

  updateColumn() {
    if (this.tableColumns) {
      this.tableSetting.columnSetting = deepClone(this.tableColumns);
    }
    this.setDisplayedColumns();
  }

  public updatePagination() {
    if (isNullOrUndefined(this.tvsDS)) {
      return;
    }
    if (this.tablePagingMode === 'client-side' || this.tablePagingMode === 'server-side') {
      this.viewportClass = 'viewport-with-pagination';
      if (!isNullOrUndefined(this.tvsDS.paginator)) {
        let dataLen = this.tvsDS.paginator.length;
        if (!isNullOrUndefined(this._tablePagination.length) && this._tablePagination.length > dataLen) {
          dataLen = this._tablePagination.length;
        }
        this.tvsDS.paginator.length = dataLen;
        if (!isNullOrUndefined(this._tablePagination.pageIndex)) {
          this.tvsDS.paginator.pageIndex = this._tablePagination.pageIndex;
        }
      }
    } else {
      this.viewportClass = 'viewport';
      if ((this.tvsDS as any)._paginator !== undefined) {
        delete (this.tvsDS as any)._paginator;
      }
    }
    this.tvsDS.refreshFilterPredicate();
  }

  setDisplayedColumns() {
    if (this.columns) {
      this.displayedColumns.splice(0, this.displayedColumns.length);
      this.columns.forEach((column: TableColumn<T>, index: number) => {
        column.index = index;
        if (column.display === undefined || column.display === 'visible' || column.display === 'prevent-hidden') {
          this.displayedColumns.push(column.key);
        }
      });
      if ((this._rowSelectionMode === 'multiple' || this._rowSelectionMode === 'single') && this.displayedColumns.indexOf('row-checkbox') === -1) {
        this.displayedColumns.unshift('row-checkbox');
      }
      if (this.tableSetting.visibleTableMenu !== false) {
        this.displayedColumns.push('table-menu');
      }
    }
  }

  refreshColumn(columns: TableColumn<T>[]) {
    if (this.viewport) {
      const currentOffset = this.viewport.measureScrollOffset();
      this.columns = columns;
      // this.setDisplayedColumns();
      setTimeout(() => this.viewport.scrollTo({ top: currentOffset, behavior: 'auto' }), 0);
    }
  }

  clearSelection() {
    if (this._rowSelectionModel) {
      this._rowSelectionModel.clear();
    }
  }

  isAllSelected() {
    const numSelected = this._rowSelectionModel.selected.length;
    const numRows = this.tvsDS.filteredData?.length;
    return numSelected === numRows;
  }

  masterToggle() {
    const isAllSelected = this.isAllSelected();
    if (isAllSelected === false) {
      this.tvsDS.filteredData.forEach(row => this._rowSelectionModel.select(row));
    } else {
      this._rowSelectionModel.clear();
    }
    this.onRowEvent.emit({
      event: 'MasterSelectionChange',
      sender: { selectionModel: this._rowSelectionModel }
    });
  }

  onRowSelectionChange(e: any, row: T) {
    if (e) {
      this._rowSelectionModel.toggle(row);
      this.onRowEvent.emit({
        event: 'RowSelectionChange',
        sender: {
          selectionModel: this._rowSelectionModel,
          row: row
        }
      });
    }
  }

  resetPaginator() {
    this.paginator.pageIndex = 0;
  }
}
