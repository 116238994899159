import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SaveViewComponent } from './save-view.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';

const components = [SaveViewComponent];

@NgModule({
  declarations: [components],
  exports: components,
  imports: [CommonModule, FormsModule, MatRadioModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatDialogModule, MatMenuModule]
})
export class SaveViewModule {}
