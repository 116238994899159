import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';
import { TemplateOrStringDirective } from './template-or-string.directive';

const components = [TooltipComponent, TooltipDirective, TemplateOrStringDirective];

@NgModule({
  declarations: [components],
  exports: components,
  imports: [CommonModule]
})
export class TooltipModule {}
