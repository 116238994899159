import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { catchError, forkJoin, map, Observable, of, tap } from 'rxjs';
import { DynamicForm } from './dynamic-form';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {
  constructor(private readonly http: HttpClient) {}

  getDynamicForm(body: any, endpointSchema: string, endpointModel: string): Observable<DynamicForm> {
    return forkJoin({
      fields: this.http.post<DynamicFormFieldsResponse>(endpointSchema, body),
      model: this.http.post<DynamicFormModelResponse>(endpointModel, body)
    }).pipe(
      map(results => {
        const dynamicForm = new DynamicForm(results.model.model, results.fields.fields);

        this.setupAutocompleteFields(dynamicForm);
        this.setupSubformButtons(dynamicForm);
        return dynamicForm;
      })
    );
  }

  setupAutocompleteFields(dynamicForm: DynamicForm) {
    const autocompletes = dynamicForm.getFieldsByType('autocomplete');
    autocompletes.forEach(field => {
      field.props = {
        ...field.props,
        filter: (term: string) => this.getAutocompleteOptions(field.props['endpoint'], term, field.model),
        onChangeSelected: option => {
          const newModel = {
            ...dynamicForm.model
          };
          const selectedOptionId = option['value'];
          newModel[field.key as string] = option;
          dynamicForm.model = newModel;
          this.prefillSubformFields(dynamicForm, selectedOptionId, field);
        }
      };

      field.props['clearSubfields'] = () => {
        // Reset all sub fields belonging to this autocomplete
        const distribute = field.templateOptions['distribute'];
        if (!distribute) return;
        const mapping = distribute['mapping'];
        if (!mapping) return;

        for (const datamodelKey in mapping) {
          const fieldKey = mapping[datamodelKey];
          dynamicForm.model[fieldKey] = null;
        }
        dynamicForm.model = { ...dynamicForm.model };
      };
    });
  }

  getAutocompleteOptions(endpoint: string, term: string, model: Record<string, any>) {
    const body = { term, model };
    return this.http.post(endpoint, body).pipe(
      map(response => response['options']),
      catchError(err => of([]))
    );
  }

  prefillSubformFields(dynamicForm: DynamicForm, selectedOptionId: string, field: FormlyFieldConfig) {
    // Get datamodel from backend for prefilling fields
    const endpoint = field.props['distribute']?.['endpoint'];
    // if no endpoint for distribute is present, noop
    if (!endpoint) {
      return;
    }

    const mapping = field.props['distribute']['mapping'];
    const fieldsToClear = field.props['distribute']['clear'];
    const attToChange = field.props['distribute']?.['att'];

    const body = {
      id: selectedOptionId,
      fields: Object.keys(mapping)
    };
    this.http.post(endpoint, body).subscribe(response => {
      // Set all values in model from response
      for (const datamodelKey in mapping) {
        const fieldKey = mapping[datamodelKey];
        if (attToChange) {
          const formField = dynamicForm.fields.find(f => f.key === fieldKey);
          formField.props = {
            ...formField.props,
            [attToChange?.key]: attToChange?.not ? !response[datamodelKey] : response[datamodelKey]
          };
        } else {
          dynamicForm.model[fieldKey] = response[datamodelKey];
        }
      }

      // Clear all fields in optional clear property
      if (fieldsToClear) {
        for (const fieldKey of fieldsToClear) {
          if (attToChange) {
            const formField = dynamicForm.fields.find(f => f.key === fieldKey);
            dynamicForm.model[fieldKey] = formField.props[attToChange?.key] ? null : false;
          } else {
            dynamicForm.model[fieldKey] = '';
          }
        }
      }

      // Form fields only update value when using spread operator on model
      dynamicForm.model = {
        ...dynamicForm.model
      };
    });
  }

  setupSubformButtons(dynamicForm: DynamicForm) {
    const autocompletes = dynamicForm.getFieldsByType('subform-button');
    autocompletes.forEach(field => {
      field.props = {
        ...field.props,
        onClick: () => this.validateAndPostSubform(field)
      };
    });
  }

  validateAndPostSubform(field: FormlyFieldConfig) {
    const endpoint = field.props['endpoint'];
    const mapping = field.props['mapping'];

    // Validate all fields belonging to subform
    let areFieldsValid = true;
    for (const fieldKey in mapping) {
      const fieldToValidate = field.form.get(fieldKey);
      fieldToValidate.markAsTouched();
      if (!fieldToValidate.valid) {
        areFieldsValid = false;
        return;
      }
    }

    // Send post with model content
    if (areFieldsValid) {
      const body = {};
      for (const fieldKey in mapping) {
        const datamodelKey = mapping[fieldKey];
        body[datamodelKey] = field.model[fieldKey];
      }
      of(body)
        .pipe(tap(body => console.log(body)))
        .subscribe(() => {
          if (field.props['messages']?.['success']) {
            alert(field.props['messages']['success']);
          }
        });
    }
  }
}

interface DynamicFormFieldsResponse {
  fields: FormlyFieldConfig[];
}

interface DynamicFormModelResponse {
  model: Record<string, any>;
  meta: any;
}
