import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RowEvent, TableColumn, TableRow} from '../../models';
import {TableComponent} from '../../components/table.component';
import {DynamicCell} from '../../core/dynamic-cell.directive';

@Component({
  selector: 'inf-table-link',
  standalone: true,
  imports: [CommonModule],
  template: `<a target="_blank" href="{{ url }}" class="file-link">{{row[this.column.key]}}</a>`,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableLinkComponent implements OnInit, DynamicCell {
  @Input() column: TableColumn<any>;
  @Input() parent: TableComponent<any>;
  @Input() row: TableRow;
  @Input() onRowEvent: EventEmitter<RowEvent>;

  url = '';

  ngOnInit(): void {
    const url: string = this.column?.option?.url ?? null;

    if (url) {
      const value = this.column?.option?.column ? this.row[this.column.option?.column] : this.row[this.column.key];
      this.url = url.replace('{key}', `${value}`) || '';
    } else {
      this.url = this.row[this.column.key];
    }
  }
}
