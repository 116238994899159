import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'inf-table-save-view',
  templateUrl: './save-view.component.html',
  styleUrls: ['./save-view.component.scss']
})
export class SaveViewComponent implements OnInit {
  title: string;
  audience = '1';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private matDialogRef: MatDialogRef<SaveViewComponent>) {}

  ngOnInit(): void {
    if (!this.data) return;
    this.title = this.data.filterTitle;
  }

  onCancel() {
    this.matDialogRef.close(undefined);
  }

  onSave() {
    if (!this.title) {
      return;
    }
    const filter = {
      title: this.title,
      audience: this.audience
    };
    this.matDialogRef.close(filter);
  }
}
