import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ContextMenuItem, PredicateFn} from '../../models/context-menu-model';
import {isNullOrUndefined} from '../../core/types';
import {TableSetting} from '../../models/table-settings.model';

@Component({
  selector: 'inf-table-row-menu',
  template: `
    <button class="clear" type="button" mat-icon-button #menuTrigger="matMenuTrigger" (click)="menuOnClick($event)"
            [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <ng-template matMenuContent>
        <ng-container *ngFor="let menu of visibleActionMenus" class="button-menu">
          <button
            mat-button
            type="button"
            [color]="menu.color"
            class="button-menu"
            *ngIf="!menu?.divider"
            [disabled]="menu.disabled"
            (click)="menuButton_OnClick(menu)">
            <mat-icon fontSet="material-icons-outlined">{{ menu.icon }}</mat-icon>
            <span>{{ menu.text }}</span>
          </button>
          <mat-divider *ngIf="menu?.divider"></mat-divider>
        </ng-container>
      </ng-template>
    </mat-menu>
  `,
  styleUrls: ['./row-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowMenuComponent<T> {
  @Input() actionMenus: ContextMenuItem[] = [];
  @Input() tableSetting: TableSetting;
  @Input() row: any;
  @Input() rowActionMenu?: { [key: string]: ContextMenuItem };
  @Output() rowActionChange: EventEmitter<ContextMenuItem> = new EventEmitter<ContextMenuItem>();

  visibleActionMenus: ContextMenuItem[] = [];

  menuOnClick(e) {
    e.stopPropagation();
    e.preventDefault();
    this.visibleActionMenus = [];
    this.actionMenus.forEach((menu: ContextMenuItem) => {
      const am: ContextMenuItem =
        isNullOrUndefined(this.rowActionMenu) || isNullOrUndefined(this.rowActionMenu[menu.name]) ? menu : this.rowActionMenu[menu.name];

      const visible = this.checkPredicate(this.row, isNullOrUndefined(am.visible) || menu.visible, true);

      if (visible) {
        this.visibleActionMenus.push({
          name: menu.name,
          text: am.text || menu.text,
          // disabled: am.disabled || menu.disabled,
          disabled: this.checkPredicate(this.row, isNullOrUndefined(am.disabled) || menu.disabled, false),
          icon: am.icon || menu.icon,
          color: am.color || menu.color,
          divider: am.divider || menu.divider
        });
      }
    });
  }

  menuButton_OnClick(menu: ContextMenuItem) {
    setTimeout(() => {
      this.rowActionChange.emit(menu);
    });
  }

  private checkPredicate(row: any, predicate: boolean | PredicateFn, defaultValue: boolean): boolean {
    if (isNullOrUndefined(predicate)) {
      return defaultValue;
    }

    if (typeof predicate === 'boolean' || predicate instanceof Boolean) {
      return predicate as boolean;
    }

    return predicate(row);
  }
}
