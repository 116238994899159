import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import * as Rx from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubSink } from 'subsink';
import { SnackbarService } from '@utils';
import { OrderableSelectDialogComponent } from './dialog-select-type/orderable-select-dialog.component';

const components = {
  'orderable-select-dialog': OrderableSelectDialogComponent
};

@Component({
  selector: 'inf-formly-multiselect-type',
  template: `
    <mat-form-field [ngClass]="{ disabled: props['disabled'] }" [ngStyle]="{ width: props['width'] + '%' }" appearance="fill">
      <mat-label>{{ props.label }}{{ props['required'] ? ' *' : '' }}</mat-label>
      <input type="text" matInput placeholder="" readonly [formControl]="formControl" [formlyAttributes]="field" hidden />
      <span>{{ labels }}</span>
      <mat-icon matSuffix (click)="openLookupDialog()">search</mat-icon>
    </mat-form-field>
  `,
  styles: [
    `
      :host mat-form-field {
        mat-icon {
          cursor: pointer;
        }

        color: rgba(0, 0, 0, 0.87);

        &.disabled {
          mat-icon {
            cursor: default;
          }
          color: rgba(0, 0, 0, 0.38);
        }
      }
    `
  ]
})
export class DialogSelectTypeComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  private _subs = new SubSink();

  JSON = JSON;
  labels: string;

  constructor(
    private readonly _dialog: MatDialog,
    private _snackbar: SnackbarService
  ) {
    super();
  }

  private dialog: any;
  private _options: any[] = [];
  private _value: any[] = [];

  ngOnInit() {
    this.dialog = typeof this.props['dialog'] === 'string' ? components[this.props['dialog']] : this.props['dialog'];

    if (!this.dialog) {
      throw new Error('dialog not provided');
    }

    if (this.formControl.value && Array.isArray(this.formControl.value)) {
      this._value = this.formControl.value;
    }
    this._subs.sink = this.formControl.valueChanges.subscribe(values => {
      this._value = values;
      this.setLabel();
    });

    if (!this.props.options) {
      this._options = [];
    } else if (Array.isArray(this.props.options)) {
      this._options = this.props.options;
    } else {
      this._subs.sink = this.props.options.subscribe(options => {
        this._options = options;
        this.setLabel();
      });
    }

    this.setLabel();
  }

  setLabel() {
    this.labels = this._value.map(v => v?.label ?? this._options.find(opt => opt.value === v)?.label).join(', ');
  }

  openLookupDialog() {
    if (this.props.disabled) {
      return;
    }
    const dialogRef = this._dialog.open(this.dialog, {
      width: '800px',
      data: {
        ...this.props?.['dialogData'],
        model: (this.model[this.key as string] as any[]) ?? []
      }
    });

    this._subs.sink = dialogRef
      .afterClosed()
      .pipe(
        filter(result => result?.action === 'save'),
        distinctUntilChanged(),
        Rx.catchError((ex, caught) => {
          const errorMessage = ex?.error?.message ? ex?.error?.message : 'Bitte versuchen Sie es erneut';
          this._snackbar.open(errorMessage, false);
          return caught;
        })
      )
      .subscribe(res => {
        const selectedValues = res?.model?.selectedValues ?? [];
        this.setDefaultValues(selectedValues);
      });
  }

  setDefaultValues(values: OptionItem[]) {
    if (this.to['multiple']) {
      this.formControl.setValue(values);
    } else {
      if (values) {
        this.formControl.setValue(values[0]);
      }
    }
  }

  override ngOnDestroy() {
    this._subs.unsubscribe();
    super.ngOnDestroy();
  }
}

export interface OptionItem {
  label: string;
  value: string;
}
