import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RowMenuComponent } from './row-menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

const components = [RowMenuComponent];

@NgModule({
  declarations: [components],
  exports: components,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule, MatDividerModule]
})
export class RowMenuModule {}
