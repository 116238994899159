<ng-content></ng-content>

<mat-menu filter-event #filterMenu="matMenu" class="menu">
  <ng-template matMenuContent>

    <div [@listAnimation]="filters.length" filter-event *ngFor="let filter of filters; let index = index" class="filter-panel">
      <mat-form-field>
        <mat-select [value]="filter.selectedIndex" [panelClass]="'mat-elevation-z10'"
                    (selectionChange)="filter.selectedIndex = $event.value;" placeholder='Conditions'
                    (keyup.enter)="applyFilter_OnClick()">
          <mat-option *ngFor="let op of filter.getOperations(); let selectedIndex=index" [value]="selectedIndex">
            {{ op.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngFor="let ctrl of filter?.parameters">
        <mat-form-field class="input-field">
          <mat-label>{{ctrl.text}}</mat-label>
          <input matInput #filterInput="matInput" [(ngModel)]="ctrl.value" [placeholder]=""
                 (keyup.enter)="applyFilter_OnClick()" autocomplete="off" />
        </mat-form-field>
      </div>

<!--      <div class="or-and">-->
<!--        <span *ngIf="filters?.length !== index+1" class="selected-filter-type">{{ filter?.type === 'and' ?-->
<!--          languagePack.filterLabels.And : languagePack.filterLabels.Or}}</span>-->
<!--        <span class="svg">-->
<!--          <mat-icon (click)="filterAction_OnClick(index,0)">add</mat-icon>-->
<!--        </span>-->
<!--        <span class="svg">-->
<!--          <mat-icon (click)="filterAction_OnClick(index,1)" style="transform: rotate(90deg);">drag_handle</mat-icon>-->
<!--        </span>-->
<!--        <span class="svg">-->
<!--          <mat-icon (click)="filterAction_OnClick(index,2)">clear</mat-icon>-->
<!--        </span>-->
<!--      </div>-->

    </div>

    <div filter-event class="menu-action">
      <button mat-raised-button type="button" (click)="clearColumn_OnClick()">{{ languagePack.filterLabels.Clear
        }}</button>
      <button mat-raised-button type="button" color="primary" (click)="applyFilter_OnClick()">{{
        languagePack.filterLabels.Search}}</button>
    </div>
  </ng-template>
</mat-menu>

<span class="trigger" [matMenuTriggerFor]="filterMenu" *ngIf="column.filter !== 'none'">
  <mat-icon>filter_list</mat-icon>
</span>
