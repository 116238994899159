import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import {RowEvent, TableColumn, TableRow} from '../../models';
import {TableComponent} from '../../components/table.component';
import {DynamicCell} from '../../core/dynamic-cell.directive';

@Component({
  selector: 'inf-table-status',
  standalone: true,
  imports: [CommonModule, MatChipsModule],
  templateUrl: './table-status.component.html',
  styleUrls: ['./table-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableStatusComponent implements DynamicCell, OnInit {
  @Input() column: TableColumn<any>;
  @Input() parent: TableComponent<any>;
  @Input() row: TableRow;
  @Input() onRowEvent: EventEmitter<RowEvent>;

  statusText = '';

  ngOnInit() {
    this.statusText = this.row[this.column.key];
  }
}
