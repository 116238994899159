<button *ngIf="visible" class="clear" type="button" mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
  <mat-icon class="main-menu">settings</mat-icon>
</button>
<div *ngIf="!visible" class="empty-menu"></div>

<mat-menu #menu="matMenu" [overlapTrigger]="false">
<!--  <button mat-menu-item type="button" (click)="screenMode_onClick()">-->
<!--    <mat-icon fontSet="material-icons-outlined">fullscreen</mat-icon>-->
<!--    <span>{{ languagePack.menuLabels.fullScreen }}</span>-->
<!--  </button>-->
  <button mat-menu-item type="button" color="primary" [matMenuTriggerFor]="convertMenu" *ngIf="currentTableSetting?.visibleActionMenu">
    <mat-icon fontSet="material-icons-outlined">save</mat-icon>
    <span>{{ languagePack.menuLabels.saveData }}</span>
  </button>

  <button mat-menu-item type="button" *ngIf="currentTableSetting?.visibleColumnSettingsMenu" (click)="columnSettings_onClick($event, null)">
    <mat-icon>view_column</mat-icon>
    <span>{{ languagePack.menuLabels.columnSetting }}</span>
  </button>

  <button mat-menu-item type="button" *ngIf="currentTableSetting?.saveSettingsMode === 'simple'"
          (click)="saveSetting_onClick($event, null)">
    <mat-icon>grading</mat-icon>
    <span>{{ languagePack.menuLabels.saveTableSetting }}</span>
  </button>
  <button mat-menu-item *ngIf="currentTableSetting?.saveSettingsMode === 'multiple'" [matMenuTriggerFor]="saveTableSetting">
    <mat-icon>grading</mat-icon>
    <span>{{ languagePack.menuLabels.saveTableSetting }}</span>
  </button>
<!--  <button mat-menu-item type="button" (click)="clearFilter_onClick()">-->
<!--    <mat-icon>clear</mat-icon>-->
<!--    <span>{{ languagePack.menuLabels.clearFilter }}</span>-->
<!--  </button>-->
</mat-menu>

<!-- Save Table Config Menu -->

<mat-menu #saveTableSetting="matMenu">
  <button mat-menu-item type="button" (click)="newSetting_onClick($event)" *ngIf="showNewSetting === false">
    <mat-icon>tune</mat-icon>
    <span>{{languagePack.menuLabels.newSetting }}</span>
  </button>
  <section *ngIf="showNewSetting === true" class="new-setting" (click)="$event.stopPropagation()">
    <div class="input-container">
      <input matInput type="text" #newSetting [placeholder]="languagePack.menuLabels.newSetting"
             [(ngModel)]="newSettingName" (keydown.enter)="applySaveSetting_onClick($event)">
    </div>
    <div class="save-table-setting">
      <mat-icon (click)="cancleSaveSetting_onClick($event)">close</mat-icon>
      <mat-icon (click)="applySaveSetting_onClick($event)">done</mat-icon>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section *ngFor="let setting of tableSetting?.settingsList" class="setting-item"
           [class.setting-item-active]="setting?.isCurrentSetting == true">
    <button mat-icon-button type="button" (click)="default_onClick($event, setting)">
      <mat-icon style="color: #dcc48f;">{{ (setting?.isDefaultSetting == true) ? 'star' : 'star_outline'}} </mat-icon>
    </button>
    <span (click)="selectSetting_onClick($event, setting)">{{setting.settingsName}}</span>
    <mat-icon (click)="saveSetting_onClick($event, setting)">save</mat-icon>
    <mat-icon style="color: #ff4081;" (click)="deleteSetting_onClick($event, setting)">delete</mat-icon>
  </section>
  <section *ngIf="tableSetting?.settingsList?.length === 0" mat-menu-item (click)="$event.stopPropagation()">
    <mat-icon style="color: #dcc48f;">lightbulb</mat-icon>
    {{languagePack.menuLabels.noSetting}}
  </section>
</mat-menu>

<!-- Convert Sub Menu -->

<mat-menu #convertMenu="matMenu">
  <button mat-menu-item type="button" (click)="download_onClick('JSON')" *ngIf="currentTableSetting?.visibleActionMenu?.json">
    <span>{{ languagePack.menuLabels.jsonFile }}</span>
  </button>
  <button mat-menu-item type="button" (click)="download_onClick('CSV')" *ngIf="currentTableSetting?.visibleActionMenu?.csv">
    <span>{{ languagePack.menuLabels.csvFile }}</span>
  </button>
</mat-menu>

<!-- Column Setting Sub Menu -->

<mat-menu #columnMenu="matMenu">
  <ng-container *ngIf="currentTableSetting?.columnSetting?.length > 0; else noColumns">
    <div class="va-mat-table-dragable-container" cdkDropList dkDropListLockAxis="y" cdkDropListOrientation="vertical"
         dir="ltr">
      <div *ngFor="let column of currentTableSetting?.columnSetting; let i = index"
           (click)="$event.stopPropagation(); $event.preventDefault()" class="dragable-row" cdkDrag
           [cdkDragData]="{ columnIndex: i, columnTitle: column.title }" (cdkDragDropped)="columnMenuDropped($event)">
        <mat-icon cdkDragHandle>drag_indicator</mat-icon>
        <mat-checkbox class="column-config" [disabled]="column?.display === 'prevent-hidden'"
                      [checked]="column?.display === 'visible' || column?.display === 'prevent-hidden'"
                      (click)="$event.stopPropagation()" (change)="toggleSelectedColumn(column)">
          {{ column.title }}
        </mat-checkbox>
<!--        <mat-icon class="column-setting-button" (click)="setting_onClick(i)" #menuTrigger="matMenuTrigger"-->
<!--                  [matMenuTriggerFor]="columnSettingMenu">settings</mat-icon>-->
        <div class="va-mat-table-drag-preview" *cdkDragPreview>
          <mat-icon>drag_indicator</mat-icon>
          <mat-checkbox [checked]="column?.display === 'visible'">
            {{ column.title }}
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="column-config-apply">
      <button mat-menu-item type="button" color="primary" class="done-setting" (click)="apply_onClick($event)">
        <mat-icon color="primary">done</mat-icon>
      </button>
      <button mat-menu-item type="button" color="primary" class="done-setting" (click)="cancel_onClick()">
        <mat-icon color="primary">clear</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-template #noColumns>
    <div mat-menu-item>
      {{ languagePack.menuLabels.thereIsNoColumn }}
    </div>
  </ng-template>
</mat-menu>

<mat-menu #columnSettingMenu="matMenu" [overlapTrigger]="false" style="padding: 10px !important">
  <div *ngIf="currentColumn !== null" (click)="$event.stopPropagation(); $event.preventDefault()"
       class="column-setting">
    <ng-container *ngIf="isVisible(currentTableSetting?.visibleActionMenu?.columnSettingFilter)">
      <div class="column-setting-header column-setting-header-first">
        <mat-icon color="primary">filter_alt</mat-icon>{{ languagePack.menuLabels.filterMode }}
      </div>
      <mat-radio-group class="radio" [(ngModel)]="currentTableSetting.columnSetting[currentColumn].filter">
        <mat-radio-button value='client-side' (click)="$event.stopPropagation()">{{
          languagePack.menuLabels.filterLocalMode }}</mat-radio-button>
        <mat-radio-button value='server-side' (click)="$event.stopPropagation()">{{
          languagePack.menuLabels.filterServerMode }}</mat-radio-button>
      </mat-radio-group>
    </ng-container>

    <ng-container *ngIf="isVisible(currentTableSetting?.visibleActionMenu?.columnSettingSort)">
      <div class="column-setting-header">
        <mat-icon color="primary">sort</mat-icon>{{ languagePack.menuLabels.sortMode }}
      </div>
      <mat-radio-group class="radio" [(ngModel)]="currentTableSetting.columnSetting[currentColumn].sort">
        <mat-radio-button value='client-side' (click)="$event.stopPropagation()">{{
          languagePack.menuLabels.sortLocalMode }}</mat-radio-button>
        <mat-radio-button value='server-side' (click)="$event.stopPropagation()">{{
          languagePack.menuLabels.sortServerMode }}</mat-radio-button>
      </mat-radio-group>
    </ng-container>

<!--    <ng-container *ngIf="isVisible(currentTableSetting?.visibleActionMenu?.columnSettingFilter)">-->
<!--      <div class="column-setting-header">-->
<!--        <mat-icon color="primary">print</mat-icon>{{ languagePack.menuLabels.printMode }}-->
<!--      </div>-->
<!--      <mat-radio-group class="radio" [(ngModel)]="currentTableSetting.columnSetting[currentColumn].printable">-->
<!--        <mat-radio-button [value]="true" (click)="$event.stopPropagation()">{{ languagePack.menuLabels.printYesMode }}-->
<!--        </mat-radio-button>-->
<!--        <mat-radio-button [value]="false" (click)="$event.stopPropagation()">{{ languagePack.menuLabels.printNoMode }}-->
<!--        </mat-radio-button>-->
<!--      </mat-radio-group>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="isVisible(currentTableSetting?.visibleActionMenu?.columnSettingPin)">-->
<!--      <div class="column-setting-header">-->
<!--        <mat-icon color="primary">push_pin</mat-icon>{{ languagePack.menuLabels.pinMode }}-->
<!--      </div>-->
<!--      <mat-radio-group class="radio" [(ngModel)]="currentTableSetting.columnSetting[currentColumn].sticky">-->
<!--        <mat-radio-button value='none' (click)="$event.stopPropagation()">{{ languagePack.menuLabels.pinNoneMode }}-->
<!--        </mat-radio-button>-->
<!--        <mat-radio-button value='start' (click)="$event.stopPropagation()">{{ languagePack.menuLabels.pinStartMode }}-->
<!--        </mat-radio-button>-->
<!--        <mat-radio-button value='end' (click)="$event.stopPropagation()">{{ languagePack.menuLabels.pinEndMode }}-->
<!--        </mat-radio-button>-->
<!--      </mat-radio-group>-->
<!--    </ng-container>-->
  </div>
</mat-menu>
