import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AbstractColumn } from '../../models/table-column.model';
import { TableIntl } from '../../core/table-intl';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Component({
  selector: 'inf-table-column-settings',
  templateUrl: './column-settings.component.html',
  styleUrls: ['./column-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnSettingsComponent {
  columnSetting: AbstractColumn[] = [];
  private columnSettingSubject = new BehaviorSubject<AbstractColumn[]>(this.columnSetting);
  selectedColumns$: Observable<AbstractColumn[]> = this.columnSettingSubject
    .asObservable()
    .pipe(map(columns => columns.filter(column => column?.display === 'visible' || column?.display === 'prevent-hidden')));

  notSelectedColumns$: Observable<AbstractColumn[]> = this.columnSettingSubject
    .asObservable()
    .pipe(map(columns => columns.filter(column => column?.display !== 'visible' && column?.display !== 'prevent-hidden')));

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public languagePack: TableIntl,
    private _dialogRef: MatDialogRef<TableColumnSettingsComponent>
  ) {
    this.columnSetting = this.data?.['columnSetting'];
    this.columnSettingSubject.next(this.columnSetting);
  }

  columnMenuDropped(event: CdkDragDrop<any>): void {
    moveItemInArray(this.columnSetting, event.previousIndex, event.currentIndex);
    this.columnSettingSubject.next(this.columnSetting);
  }

  toggleSelectedColumn(column: AbstractColumn) {
    const currentSelectedLength = this.columnSetting.filter(column => column?.display === 'visible' || column?.display === 'prevent-hidden').length;
    const alphabeticNonSelectedIndex = (this.columnSetting as any).findLastIndex(col => col.display !== 'visible' && col.display !== 'prevent-hidden' && col.title.localeCompare(column.title) === - 1);
    column.display = column.display === 'visible' ? 'hidden' : 'visible';
    if (column?.display !== 'hidden') {
      moveItemInArray(this.columnSetting, this.columnSetting.findIndex(e => e.key === column?.key), currentSelectedLength);
    } else {
      moveItemInArray(this.columnSetting, this.columnSetting.findIndex(e => e.key === column?.key), alphabeticNonSelectedIndex === - 1 ? currentSelectedLength - 1 : alphabeticNonSelectedIndex);
    }
    this.columnSettingSubject.next(this.columnSetting);
  }

  onSave(e) {
    e.stopPropagation();
    e.preventDefault();
    this._dialogRef.close({ action: 'save', model: this.columnSetting });
  }

  onCancel() {
    this._dialogRef.close(undefined);
  }
}
