import {ChangeDetectionStrategy, Component, EventEmitter, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {RowEvent, TableColumn, TableRow} from '../../models';
import {TableComponent} from '../../components/table.component';
import {DynamicCell} from '../../core/dynamic-cell.directive';

@Component({
  selector: 'inf-table-boolean',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  template: `
    <mat-icon *ngIf="row[column.key] === true" [ngClass]="'green'">check</mat-icon>
    <mat-icon *ngIf="row[column.key] === false" [ngClass]="'red'">close</mat-icon>
    <mat-icon *ngIf="row[column.key] === null || row[column.key] === undefined">remove</mat-icon>
  `,
  styleUrls: [`./table-boolean.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableBooleanComponent implements DynamicCell {
  @Input() column: TableColumn<any>;
  @Input() parent: TableComponent<any>;
  @Input() row: TableRow;
  @Input() onRowEvent: EventEmitter<RowEvent>;
}
