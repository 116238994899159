<div class="gsa-sub-nav">
  @for (group of navItems;track group.name) {
    <div class="gsa-sub-nav__group">
      <div class="gsa-sub-nav__title">
        {{ group.name }}
      </div>

      @for (item of group.items;track item.id) {
        <div class="gsa-sub-nav__item" routerLinkActive="selected" [routerLink]="item.routerLink" title>
          <inf-icon [icon]="item.icon" size="md"></inf-icon>
          <span>{{ item.label }}</span>
        </div>
      }
    </div>
  }
</div>
