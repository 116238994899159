import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyFieldProps } from '@ngx-formly/material/form-field';
import { subtractSet } from '@utils/utilts';
import { BehaviorSubject, filter } from 'rxjs';
import { SubSink } from 'subsink';

// NOTE: Use symbols for custom props to avoid name collisions with field
// properties and prevents being shown in Object.keys(). Setting as "any" type to
// prevent TypeScript errors when using these symbols as object keys.
const LAST_CONDITION_VALUE_KEY = Symbol('lastConditionValue') as any;
const DEPENDENCY_DIRTY_KEY = Symbol('dependencyDirty') as any;

export class DynamicForm<T = any> {
  // Field configs from backend responses contain "templateOptions" as needed for Formly 5
  // Cockpit uses Formly 6 where templateOptions has been renamed to props
  private _replaceTemplateOptionsWithProps(fields: FormlyFieldConfig[]): FormlyFieldConfig[] {
    const newFields: FormlyFieldConfig[] = [];
    for (const field of fields) {
      if (!field.templateOptions) {
        newFields.push(field);
        continue;
      }
      const newField = { ...field, props: field.templateOptions };
      delete newField.templateOptions;
      if (newField.fieldGroup) {
        newField.fieldGroup = this._replaceTemplateOptionsWithProps(newField.fieldGroup);
      }
      newFields.push(newField);
    }
    return newFields;
  }

  form: FormGroup = new FormGroup({});
  isAllFieldsDisabled = new BehaviorSubject(false);
  modelSubject = new BehaviorSubject<T>(null);
  allModelFields = new BehaviorSubject<Set<string>>(new Set([]));
  fieldsWithoutFieldGroups: FormlyFieldConfig[] = [];
  subs = new SubSink();

  private _model: T;
  public get model() {
    return this._model;
  }
  public set model(model: T) {
    this._model = new Proxy(model as any, {
      set: (target, key, value) => {
        if (target[key] !== value) {
          // Only update if value has changed
          target[key] = value;
          this.modelSubject.next({ ...target });
        }
        return true;
      }
    });
    this.modelSubject.next({ ...model });
  }

  public getModelForSending() {
    // Formly sets existing model fields to undefined on hiding a field, but backend needs null to overwrite existing values
    // have to set the fields to null manually on getting/sending
    const model = { ...this.model };
    const modelFieldsToSetToNull = subtractSet(this.allModelFields.getValue(), new Set(Object.keys(this.model)));
    modelFieldsToSetToNull.forEach(field => (model[field] = null));
    return model;
  }

  private _fields: FormlyFieldConfig[];
  public get fields() {
    return this._fields;
  }
  public set fields(fields: FormlyFieldConfig[]) {
    const replacedFields = this._replaceTemplateOptionsWithProps(fields);
    this._fields = replacedFields;
    this.fieldsWithoutFieldGroups = this.filterFieldsIgnoringFieldGroupsRecursive(this.fields, () => true);
    // Set up model listeners for all fields with "dependencies" in props
    this.setupDependencies();
    // Remember all model fields that have ever been defined
    // To make it possible to set fields that have been undefined in the meantime to null when sending
    this.subs.sink = this.modelSubject.subscribe(() => {
      if (this.model) {
        this.allModelFields.next(new Set([...this.allModelFields.getValue(), ...new Set(Object.keys(this.model))]));
      }
    });
  }

  constructor(
    model: T = {} as T,
    fields: FormlyFieldConfig[] = [],
    public meta: any = {},
    public options: FormlyFormOptions = {}
  ) {
    this.fields = fields;
    this.model = model;
    this.registerAllFieldsDisabledListener();
  }

  setAllFieldsDisabled() {
    this.isAllFieldsDisabled.next(true);
  }

  private registerAllFieldsDisabledListener() {
    this.isAllFieldsDisabled.subscribe(isAllFieldsDisabled => {
      if (isAllFieldsDisabled) {
        this._setAllFieldsDisabled();
      }
    });
  }

  private setupDependencies() {
    // Set up model listeners for all fields with "dependencies" in templateOptions
    this.fieldsWithoutFieldGroups.forEach(field => {
      // TODO: Detect direct/indirect loops if fields depend on each other
      // (e.g. field A depends on field B, field B depends on field C, field C depends on field A)
      // => tree shaking on dependency.fieldName?
      if (!field.props) return;
      const dependencies = field.props['dependencies'] as DynamicFormFieldDependency[];
      if (!dependencies) return;
      this.registerDependencyListener(field, dependencies);
    });
  }

  private registerDependencyListener(field: FormlyFieldConfig, dependencies: DynamicFormFieldDependency[]) {
    // Some impromptu type checking
    if (!dependencies.length || !(dependencies.length > 0) || !dependencies[0].conditions) return;
    this.modelSubject.pipe(filter(f => f !== null)).subscribe(() => {
      if (Object.keys(this.model).length !== 0) {
        dependencies.forEach(dependency => {
          dependency.conditions.forEach(condition => {
            const conditionValue = this.checkDependencyCondition(condition);
            const newOptions = conditionValue ? dependency.onTrueSet : dependency.onFalseSet;
            if (newOptions) {
              this.updateFieldOptions(field, newOptions, conditionValue);
            }
          });
        });
      }
    });
  }

  private checkDependencyCondition(condition: DynamicFormFieldDependencyCondition, recursionInfo = { currentDepth: 0, maxDepth: 10 }) {
    if (recursionInfo.currentDepth > recursionInfo.maxDepth) {
      throw new Error('Dynamic form field dependency condition check recursion limit reached');
    }
    switch (condition.kind) {
      case 'single':
        return this.checkDependencyConditionSingle(condition as DynamicFormFieldDependencyConditionSingle);
      case 'combined':
        return this.checkDependencyConditionCombined(condition as DynamicFormFieldDependencyConditionCombined, recursionInfo);
    }
  }

  private checkDependencyConditionSingle(condition: DynamicFormFieldDependencyConditionSingle) {
    // Get value, either directly from model or from nested object via property path
    const dependencyValue = condition.dependencyPathToValue
      ? condition.dependencyPathToValue.reduce((prev, curr) => (prev && prev[curr] ? prev[curr] : prev), this.model[condition.dependencyField])
      : this.model[condition.dependencyField];

    // Allow checking for empty field
    const checkForEmptyIntended = condition.dependencyValues.some(value => value === null || value === undefined);
    const valueIsEmpty = dependencyValue === null || dependencyValue === undefined;
    const checkForEmptySuccessful = checkForEmptyIntended && valueIsEmpty;

    return checkForEmptySuccessful || condition.dependencyValues.includes(dependencyValue);
  }

  private checkDependencyConditionCombined(condition: DynamicFormFieldDependencyConditionCombined, recursionInfo: { currentDepth: number; maxDepth: number }) {
    recursionInfo.currentDepth++;
    switch (condition.type) {
      case 'and':
        return condition.conditions.every(condition => this.checkDependencyCondition(condition, recursionInfo));
      case 'or':
        return condition.conditions.some(condition => this.checkDependencyCondition(condition, recursionInfo));
    }
  }

  private updateFieldOptions(field: FormlyFieldConfig, newOptions: DynamicFormFieldDependencyOptions, conditionValue: boolean) {
    if (!field.props) return;

    // See if field has already been updated by a dependency (not on initial load)
    const isFirstLoad = !field.props[DEPENDENCY_DIRTY_KEY];

    // Set new value if condition has just changed (Don't run on first load if model value already defined)
    const conditionHasChanged = this.isBoolean(field.props[LAST_CONDITION_VALUE_KEY]) && field.props[LAST_CONDITION_VALUE_KEY] !== conditionValue;

    const newProps: FormlyFieldProps = {};

    newProps[LAST_CONDITION_VALUE_KEY] = conditionValue;

    if (newOptions.value !== undefined && (conditionHasChanged || this.model[field.key as string] === undefined)) {
      setTimeout(() => (this.model[field.key as string] = newOptions.value));
    }

    if (this.isBoolean(newOptions.hide) && field.hide !== newOptions.hide) {
      if (newOptions.hide === true && !isFirstLoad) {
        // clear field
        this.model[field.key as string] = null;
      }
      field.hide = newOptions.hide;
    }

    if (this.isBoolean(newOptions.options?.readonly)) {
      newProps.readonly = newOptions.options.readonly;
    }
    if (this.isBoolean(newOptions.options?.disabled) && !this.isAllFieldsDisabled.getValue()) {
      newProps.disabled = newOptions.options.disabled;
    }
    if (this.isBoolean(newOptions.options?.required)) {
      newProps.required = newOptions.options.required;
    }
    if (field.type === 'select' && newOptions.options?.selectOptions) {
      newProps.options = newOptions.options.selectOptions;
    }
    newProps[DEPENDENCY_DIRTY_KEY] = true;

    // Set new properties
    field.props = {
      ...field.props,
      ...newProps
    };
  }

  isBoolean(value: any) {
    return typeof value === 'boolean';
  }

  // Tries to set all fields to disabled
  private _setAllFieldsDisabled() {
    const fieldsWithoutFieldGroups = this.filterFieldsIgnoringFieldGroupsRecursive(this.fields, () => true);
    fieldsWithoutFieldGroups.forEach(field => {
      field.props = {
        ...(field.props ?? {}),
        disabled: true
      };
    });
  }

  setAllReadonlyFieldsDisabled() {
    const fieldsWithoutFieldGroups = this.filterFieldsIgnoringFieldGroupsRecursive(this.fields, field => field.props?.readonly);
    fieldsWithoutFieldGroups.forEach(field => {
      field.props = {
        ...(field.props ?? {}),
        disabled: true
      };
    });
  }

  getFieldByKey(key: string) {
    return this.filterFieldsIgnoringFieldGroupsRecursive(this.fields, field => field.key === key)[0];
  }

  getFieldsByType(type: string) {
    return this.filterFieldsIgnoringFieldGroupsRecursive(this.fields, field => field.type === type);
  }

  // Field groups introduce nesting in the fields array, so a simple flat filter function can't find fields inside field groups
  private filterFieldsIgnoringFieldGroupsRecursive(
    fields: FormlyFieldConfig[],
    predicate: (value: FormlyFieldConfig) => boolean,
    maxRecDepth = 10,
    currentRecDepth = 1
  ): FormlyFieldConfig[] {
    if (currentRecDepth > maxRecDepth) {
      return [];
    }
    let filteredFields = [];
    for (const field of fields) {
      if (field.fieldGroup) {
        filteredFields = filteredFields.concat(this.filterFieldsIgnoringFieldGroupsRecursive(field.fieldGroup, predicate, maxRecDepth, currentRecDepth + 1));
      } else {
        filteredFields = filteredFields.concat([field].filter(predicate));
      }
    }
    return filteredFields;
  }
}

export type DynamicFormFieldDependencyCondition = DynamicFormFieldDependencyConditionSingle | DynamicFormFieldDependencyConditionCombined;

export class DynamicFormFieldDependencyConditionSingle {
  kind = 'single';
  dependencyField: string;
  dependencyPathToValue: string[];
  dependencyValues: any[];
}

export class DynamicFormFieldDependencyConditionCombined {
  kind = 'combined';
  type: 'and' | 'or';
  conditions: DynamicFormFieldDependencyCondition[];
}

export class DynamicFormFieldDependencyOptions {
  value?: any;
  hide?: boolean;
  options?: {
    readonly?: boolean;
    disabled?: boolean;
    required?: boolean;
    selectOptions?: any;
  };
}

export class DynamicFormFieldDependency {
  conditions: DynamicFormFieldDependencyCondition[];
  onTrueSet?: DynamicFormFieldDependencyOptions;
  onFalseSet?: DynamicFormFieldDependencyOptions;
}
