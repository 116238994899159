import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicForm } from '../../core/dynamic-form';
import { SubSink } from 'subsink';
import { DynamicFormService } from '../../core/dynamic-form.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

export type DialogResult<T> = {
  action: 'cancel' | 'save';
  model: T;
};

export interface DialogConfig<T> {
  title: string;
  body?: { formIdentifier: string; meta: any };
  schemaUrl?: string;
  modelUrl?: string;
  model?: T;
  schema?: FormlyFieldConfig[];
  showSaveButton?: boolean;
  showCancelButton?: boolean;
  cancelButtonText?: string;
}

@Component({
  selector: 'inf-form-dialog',
  template: `
    <div class="header">
      <span mat-dialog-title>{{ dialogData.title }}</span>
      <mat-icon (click)="cancel()">clear</mat-icon>
    </div>
    <mat-dialog-content class="dialogContent">
      <inf-form [form]="dynamicForm"></inf-form>
    </mat-dialog-content>
    <mat-dialog-actions>

      <button class="button" mat-button type="button" *ngIf="showCancelButton && showSaveButton" (click)="cancel()">{{cancelButtonText}}</button>
      <button class="button" mat-raised-button color="primary" type="button" *ngIf="showCancelButton && !showSaveButton" (click)="cancel()">{{cancelButtonText}}</button>
      <button class="button" mat-raised-button color="primary" *ngIf="showSaveButton" (click)="save()">Speichern</button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      @use '../../../../../styles/base' as inf;

      .header {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 0 10px;

        span {
          //margin-bottom: 16px;
          font-weight: 500;
          font-size: inf.$font-size-normal;
        }

        mat-icon {
          margin-top: 1px;
          cursor: pointer;
        }
      }

      mat-dialog-actions {
        justify-content: flex-end;

        .button {
          margin: 0 8px;
          cursor: pointer;
        }

        div {
          font-weight: 400;
          font-size: inf.$font-size-normal;
        }
      }

      mat-dialog-content {
        border-top: solid 1px #e5e5e5;
        border-bottom: solid 1px #e5e5e5;

        padding-top: 16px;
        padding-bottom: 8px;
      }

      .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
        padding-top: 22px !important;
        padding-bottom: 6px !important;
      }

      .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
        //.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
        display: block !important;
        font-size: 14px !important;
      }

      .mat-mdc-option .mdc-list-item__primary-text {
        font-size: 14px !important;
      }

      ::ng-deep .mat-mdc-select-value-text {
        font-size: 14px !important;
      }

      /* Hide number input arrows */
      /* Chrome, Safari, Edge, Opera */
      ::ng-deep input::-webkit-outer-spin-button,
      ::ng-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      ::ng-deep input[type='number'] {
        -moz-appearance: textfield;
      }
    `
  ]
})
export class FormDialogComponent<T> implements OnInit, OnDestroy {
  dynamicForm: DynamicForm<T> = new DynamicForm();
  cancelButtonText = 'Abbrechen';
  showSaveButton = true;
  showCancelButton = true;


  private _subs = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly dialogData: DialogConfig<T>,
    private readonly dialogRef: MatDialogRef<FormDialogComponent<T>, DialogResult<T>>,
    private dynamicFormService: DynamicFormService
  ) {

    this.showCancelButton = this.dialogData?.showCancelButton ?? true;
    this.showSaveButton = this.dialogData?.showSaveButton ?? true;
    this.cancelButtonText = this.dialogData?.cancelButtonText ?? 'Abbrechen';
  }

  ngOnInit() {
    if (!this.dialogData.schema) {
      this._subs.sink = this.dynamicFormService
        .getDynamicForm(this.dialogData?.body, this.dialogData?.schemaUrl, this.dialogData?.modelUrl)
        .subscribe(form => (this.dynamicForm = form));
    } else {
      this.dynamicForm = new DynamicForm(this.dialogData?.model, this.dialogData?.schema);

      this.dynamicFormService.setupAutocompleteFields(this.dynamicForm);
      this.dynamicFormService.setupSubformButtons(this.dynamicForm);
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  cancel() {
    this.dynamicForm.form.markAsUntouched();
    this.dialogRef.close({ action: 'cancel', model: null });
  }

  save() {
    this.dynamicForm.form.markAllAsTouched();
    if (this.dynamicForm.form.valid) {
      this.dynamicForm.form.markAsUntouched();
      this.dialogRef.close({ action: 'save', model: this.dynamicForm.getModelForSending() });
    }
  }
}
