<div class="inf-table-column-settings">
  <div class="header">
    <h3 mat-dialog-title>{{ languagePack.menuLabels.columnSetting }}</h3>
    <mat-icon (click)="onCancel()">clear</mat-icon>
  </div>

  <mat-dialog-content class="dialogContent">
    <div class="select-list">
      <div>Verfügbare Spalten</div>
      <div>Ausgewählte Spalten</div>
      <div class="select-options">
        <div class="select-options-item" *ngFor="let column of (notSelectedColumns$|async); let i = index"
             (click)="toggleSelectedColumn(column)">
          <span>{{ column.title }}</span>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <div class="selected-options" cdkDropList cdkDropListLockAxis="y" cdkDropListOrientation="vertical" dir="ltr">
        <div
          class="selected-options-item"
          cdkDrag
          cdkDragPreviewContainer="parent"
          [cdkDragData]="{ columnIndex: column.index, columnTitle: column.title }"
          (cdkDragDropped)="columnMenuDropped($event)"
          (click)="$event.stopPropagation(); $event.preventDefault()"
          *ngFor="let column of (selectedColumns$|async); let i = index">
          <div class="selected-options-item-placeholder" *cdkDragPlaceholder></div>
          <mat-icon (click)="toggleSelectedColumn(column)">close</mat-icon>
          <span>{{ column.title }}</span>
          <mat-icon cdkDragHandle>drag_indicator</mat-icon>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="button" (click)="onCancel()">Abbrechen</div>
    <button class="button" mat-raised-button color="primary" (click)="onSave($event)">Speichern</button>
  </mat-dialog-actions>
</div>

<!--<ng-container *ngIf="columnSetting?.length > 0; else noColumns">-->
<ng-template #noColumns>
  <div mat-menu-item>{{ languagePack.menuLabels.thereIsNoColumn }}</div>
</ng-template>
