import { AbstractColumn } from './table-column.model';
import { TableScrollStrategy } from '../core/fixed-size-table-virtual-scroll-strategy';

export type DisplayMode = 'visible' | 'hidden' | 'none';
export type DownloadMode = 'server-side' | 'client-side';

export interface TableSetting {
  pageSize?: number;
  columnSetting?: AbstractColumn[];
  visibleActionMenu?: VisibleActionMenu;
  visibleColumnSettingsMenu?: boolean;
  visibleTableMenu?: boolean;
  visibleHeaderMenu?: boolean;
  alternativeRowStyle?: any;
  normalRowStyle?: any;
  scrollStrategy?: TableScrollStrategy;
  rowStyle?: any;
  enableContextMenu?: boolean;
  autoHeight?: boolean;
  saveSettingsMode?: 'simple' | 'multiple' | 'none';
  settingsName?: string;
  settingsList?: SettingItem[];
  options?: TableOptions;
}

export interface SettingItem extends TableSetting {
  isCurrentSetting?: boolean;
  isDefaultSetting?: boolean;
}

export interface VisibleActionMenu {
  json?: boolean;
  csv?: boolean;
  columnSetting?: boolean;
  columnSettingOrder?: boolean;
  columnSettingFilter?: boolean;
  columnSettingSort?: boolean;
  saveTableSetting?: boolean;
  clearFilter?: boolean;
}

export interface TableOptions {
  downloadMode?: DownloadMode;
}
