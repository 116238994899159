import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatChipsModule} from "@angular/material/chips";
import {RowEvent, TableColumn, TableRow} from '../../models';
import {TableComponent} from '../../components/table.component';
import {DynamicCell} from '../../core/dynamic-cell.directive';

@Component({
  selector: 'inf-table-chips',
  standalone: true,
  imports: [CommonModule, MatChipsModule],
  template: `
    <mat-chip-listbox>
      <mat-chip *ngFor="let chip of chips">{{chip}}</mat-chip>
    </mat-chip-listbox>
  `,
  styleUrls: [`./table-chips.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableChipsComponent implements OnInit, DynamicCell {
  @Input() column: TableColumn<any>;
  @Input() parent: TableComponent<any>;
  @Input() row: TableRow;
  @Input() onRowEvent: EventEmitter<RowEvent>;

  chips: string[] = [];

  ngOnInit() {
    this.chips = [...this.row[this.column.key]];
  }
}
