import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormComponent} from './components/form/form.component';
import {ReactiveFormsModule} from "@angular/forms";
import {FormlyModule} from "@ngx-formly/core";
import {FormDialogComponent} from './components/form-dialog/form-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {FormlyMaterialModule} from "@ngx-formly/material";

@NgModule({
  declarations: [
    FormComponent,
    FormDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    FormlyMaterialModule,
    MatButtonModule,
    FormlyModule.forChild({})
  ],
  exports: [FormComponent, FormDialogComponent]
})
export class DynamicFormModule {
}
