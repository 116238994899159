export interface HashMap<T> {
  [key: string]: T;
}

export const isNullOrUndefined = (value: any): boolean => value === null || value === undefined;

export const getObjectProp = (fieldName: string, defaultValue: any, ...variable: any[]) => {
  for (const v in variable) {
    if (variable[v] && !isNullOrUndefined(variable[v][fieldName])) {
      return variable[v][fieldName];
    }
  }
  return defaultValue;
};

/**
 * Simplifies a string (trims and lowerCases)
 */
export function simplify(s: string): string {
  return `${s}`.trim().toLowerCase();
}

/**
 * Transforms a camelCase string into a readable text format
 * @example textify('helloWorld!')
 * // Hello world!
 */
export function textify(text: string) {
  return text.replace(/([A-Z])/g, char => ` ${char.toLowerCase()}`).replace(/^([a-z])/, char => char.toUpperCase());
}

/**
 * Transforms a text string into a title case text format
 * @example titleCase('hello world!')
 * // Hello World!
 */
export function titleCase(value: string) {
  const sentence = value.toLowerCase().split(' ');
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(' ');
}
