import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { RowEvent, TableColumn, TableRow } from '../../models';
import { TableComponent } from '../../components/table.component';
import { DynamicCell } from '../../core/dynamic-cell.directive';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

export interface TableDropdownOptions {
  label: string;
  value: string;
  icon?: string;
  color?: 'red';
  border?: 'top';
}

@Component({
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatIconModule],
  selector: 'inf-table-dropdown',
  templateUrl: './table-dropdown.component.html',
  styleUrls: ['./table-dropdown.component.scss']
})
export class TableDropdownComponent implements OnInit, DynamicCell {
  @Input() column: TableColumn<any>;
  @Input() parent: TableComponent<any>;
  @Input() row: TableRow;
  @Input() onRowEvent: EventEmitter<RowEvent>;

  options: TableDropdownOptions[];

  ngOnInit(): void {
    this.options = this.column?.option;
    if (this.row['disabled']) {
      this.row.options = {
        disabled: true,
        selected: false
      };
    }
  }

  onSelect(e) {
    this.onRowEvent.emit({ event: 'DropdownSelect', sender: { row: this.row, e } });
  }

  onClick(e) {
    this.onRowEvent.emit({ event: 'DropdownClick', sender: { row: this.row, e } });
  }

  getDropdownLabel() {
    const value = this.row['inputValue'] || '';
    const option = this.options.find(o => o.value === value);
    return option?.label || '';
  }
}
