import { TableRow } from './table-row.model';
import { TemplateRef } from '@angular/core';
import { IPipe } from './pipe.model';

export declare type RenderFn<TRow extends TableRow> = (row: TRow) => string;
export declare type ClassFn = (row: any, col: any) => string;
export declare type SortFn<TRow extends TableRow> = (data: TRow[], col: any) => string;
export declare type FilterFn<TRow extends TableRow> = (data: TRow[], col: any) => string;
export declare type ColumnType = 'text' | 'number' | 'date' | 'selection' | 'link' | 'boolean';
export declare type ColumnDisplay = 'visible' | 'hidden' | 'prevent-hidden';
export declare type ColumnSticky = 'start' | 'end' | 'none';
export declare type ColumnFilterType = 'client-side' | 'server-side' | 'none';
export declare type ColumnSort = 'client-side' | 'server-side' | 'none';
export declare type ToExport = (row: any, type: any) => any;

export interface TableColumn<TRow extends TableRow> extends AbstractColumn {
  classNames?: string;
  rowClass?: string | ClassFn;
  customSortFunction?: SortFn<TRow>;
  customFilterFunction?: FilterFn<TRow>;
  toExport?: ToExport;
}

export interface AbstractColumn {
  index?: number;
  key: string; // Key of data
  type?: ColumnType; // Type of data
  template?: TemplateRef<any>; // Template to use
  minWidth?: number; // Min width of column
  width?: number; // Width of column
  widthPercentage?: number; // Width in percentage of column
  widthUnit?: 'px' | '%'; // Width unit
  style?: any; // Private property used only in html
  title?: string; // Title of column
  isKey?: boolean;
  display?: ColumnDisplay; // Show or hide the column
  sticky?: ColumnSticky; // Stick this column at start or end or none
  filter?: ColumnFilterType; // Filter
  sort?: ColumnSort; // Sort
  cellClass?: string; // Apply a class to a cell, the class name must be in global stylesheet
  cellStyle?: any; // Apply a style to a cell, style must be an object
  icon?: string; // Set icon in columns
  iconColor?: string; // Set Icon color
  dynamicCellComponent?: any; // Set dynamic component in cell
  draggable?: boolean; // Column draggable
  clickable?: boolean; // Clickable
  clickType?: 'cell' | 'label' | 'custom'; // Click type
  exportable?: boolean; // Exportable
  enableContextMenu?: boolean; // Enable context menu
  rowSelectable?: boolean; // Row Selectable
  cellEllipsisRow?: number; // Ellipsis Text
  cellTooltipEnabled?: boolean; // Cell tooltip
  headerEllipsisRow?: number; // Header Ellipsis
  headerTooltipEnable?: boolean; // Header Tooltip
  toString?: (column: TableColumn<any>, row: TableRow) => string; // ToString
  option?: any; // For Storing shared data shown in the cell of column
  customSort?: (column: TableColumn<any>, row: any) => string; // Custom Sort
  pipes?: IPipe[];
}
