<mat-select
  [disabled]="row['disableDropdown'] === true"
  [(value)]="row['inputValue']"
  [ngClass]="{'input-dropdown': true, 'disabledDropdown' : row['disableDropdown'] === true}"
  (selectionChange)="onSelect($event)"
  (click)="onClick($event)">
  <mat-select-trigger class="input-dropdown-trigger">
    <span [ngClass]="{'disabledDropdown' : row['disabled'] === true}">{{ getDropdownLabel() }}</span>
  </mat-select-trigger>
  <mat-option
    *ngFor="let option of options"
    [value]="option.value"
    [ngClass]="{'input-dropdown-option' : true, 'red' : option.color === 'red','border-top' : option.border === 'top'}">
    <mat-icon *ngIf="option.icon">{{ option.icon }}</mat-icon>
    <span [ngClass]="{'no-icon-option': !option.icon}">{{option.label}}</span>
  </mat-option>
</mat-select>
