import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import {RowEvent, TableColumn, TableRow} from '../../models';
import {TableComponent} from '../../components/table.component';
import {DynamicCell} from '../../core/dynamic-cell.directive';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'inf-table-toeb-rollen',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  template: `
    <mat-icon *ngIf="isToeb === true" [ngClass]="'green'">check</mat-icon>
    <mat-icon *ngIf="isToeb === false" [ngClass]="'red'">close</mat-icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableToebRollenComponent implements DynamicCell, OnInit {
  @Input() column: TableColumn<any>;
  @Input() parent: TableComponent<any>;
  @Input() row: TableRow;
  @Input() onRowEvent: EventEmitter<RowEvent>;

  roles: any[] = [];
  isToeb: boolean = false;

  ngOnInit() {
    this.roles = this.row[this.column.key];
    this.isToeb = this.roles?.[0].id == 'toeb';
  }
}
